import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import { useMediaPredicate } from "react-media-hook"
import Slider from "react-slick"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { CardSlider } from "../../../components/card-slider/card-slider"
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Section, Container } from "../../../components/grid/grid"
import ans6 from "../../../assets/img/ans6.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const WishForHomeFaqPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        propertyType(id: "faq", idType: SLUG) {
          acfPropertyType {
            banner {
              sourceUrl
            }
            image {
              sourceUrl
            }
            sliderImageBanner {
              link
              image {
                sourceUrl
              }
            }
            sliderImageMobile {
              link
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const wordPress = staticData.wordPress.propertyType
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  let listOfImages = []
  let listImagesBanner = wordPress.acfPropertyType.sliderImageBanner
  let listImagesMobile = wordPress.acfPropertyType.sliderImageMobile

  if(!resizeScreen){
    listImagesBanner &&
      listImagesBanner.map(item => {
        listOfImages.push({image:item.image.sourceUrl, url:item.link})
      })
  }else{
    listImagesMobile &&
      listImagesMobile.map(item => {
        listOfImages.push({image:item.image.sourceUrl, url:item.link})
      })
  }

  const settings = {
    autoplay: listOfImages.length > 1 ? true : false,
    autoplaySpeed: listOfImages.length > 1 ? 4000 : 0,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  useEffect(() => {
    let listOfImages = []
    let listImagesBanner = wordPress.acfPropertyType.sliderImageBanner
    let listImagesMobile = wordPress.acfPropertyType.sliderImageMobile

    if(!resizeScreen){
      listImagesBanner &&
        listImagesBanner.map(item => {
          listOfImages.push({image:item.image.sourceUrl, url:item.link})
        })
    }else{
      listImagesMobile &&
        listImagesMobile.map(item => {
          listOfImages.push({image:item.image.sourceUrl, url:item.link})
        })
    }

    document.querySelector(".animated").style.marginTop = "0px"
    document.querySelector(".sliderWfh").style.paddingTop = "0px"
    document.querySelector(".sliderWfh").style.paddingBottom = "0px"
  }, [])

  useEffect(() => {
    const clicker1 = document.getElementById("ach1")
    const clicker2 = document.getElementById("ach2")
    const clicker3 = document.getElementById("ach3")
    const clicker4 = document.getElementById("ach4")
    const clicker5 = document.getElementById("ach5")
    const clicker6 = document.getElementById("ach6")
    const clicker7 = document.getElementById("ach7")
    const clicker8 = document.getElementById("ach8")

    if(resizeScreen){
      clicker1.addEventListener('click', function(){
        window.scrollTo(0,800)
      })
  
      clicker2.addEventListener('click', function(){
        window.scrollTo(0,870)
      })
  
      clicker3.addEventListener('click', function(){
        window.scrollTo(0,940)
      })
  
      clicker4.addEventListener('click', function(){
        window.scrollTo(0,1010)
      })
      
      clicker5.addEventListener('click', function(){
        window.scrollTo(0,1070)
      })
  
      clicker6.addEventListener('click', function(){
        window.scrollTo(0,1130)
      })
  
      clicker7.addEventListener('click', function(){
        window.scrollTo(0,1195)
      })
  
      clicker8.addEventListener('click', function(){
        window.scrollTo(0,1255)
      })
    }else{
      clicker1.addEventListener('click', function(){
        window.scrollTo(0,655)
      })
  
      clicker2.addEventListener('click', function(){
        window.scrollTo(0,750)
      })
  
      clicker3.addEventListener('click', function(){
        window.scrollTo(0,836)
      })
  
      clicker4.addEventListener('click', function(){
        window.scrollTo(0,910)
      })
      
      clicker5.addEventListener('click', function(){
        window.scrollTo(0,988)
      })
  
      clicker6.addEventListener('click', function(){
        window.scrollTo(0,1052)
      })
  
      clicker7.addEventListener('click', function(){
        window.scrollTo(0,1135)
      })
  
      clicker8.addEventListener('click', function(){
        window.scrollTo(0,1195)
      })
    }

  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO title="FAQ Wish For Home" />
      <Helmet>
        <body className="bd-page" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Section className="sliderWfh" style={{padding:"0px"}}>
        <Slider {...settings}>
          {listOfImages.length !== null &&(
            listOfImages.map(item => {
              return(
                <div>
                  <img src={item.image} style={{width:"100%"}} onClick={()=> window.open(item.url, "_blank")}/>
                </div>
              )
            })
          )}
        </Slider>
      </Section>
      <Section>
        <Container className="container-md">
          <h2>FAQ Wish For Home</h2>
          Pertanyaan yang sering ditanyakan seputar program Wish for Home Sinar
          Mas Land
        </Container>
      </Section>
      <Section className="main-faq">
        <div
          className={`nav-tabs-wrapper d-flex justify-content-center`}
          style={{ borderBottom: "none" }}
        >
          <ul className={`nav nav-tabs`} role="tablist">
            <li className={`nav-item`}>
              <Link className={`nav-link`} role="tab" to="/wishforhome">
                Properti
              </Link>
            </li>
            <li className={`nav-item`}>
              <Link className={`nav-link`} role="tab" to="/wishforhome/kavling">
                Kavling
              </Link>
            </li>
            <li className={`nav-item`}>
              <div className={`nav-link active`} role="tab">
                FAQ
              </div>
            </li>
          </ul>
        </div>
        <Container>
          <Accordion preExpanded={[1]} allowZeroExpanded>
            <AccordionItem uuid={1}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  FAQ Wish For Home Sinarmasland
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Accordion allowZeroExpanded>
                  <AccordionItem id="aci1">
                    <AccordionItemHeading id="ach1">
                      <AccordionItemButton>
                        1.Apa itu Wish For Home?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Wish For Home adalah promo marketing program dari Sinar
                        Mas Land. Program ini berlangsung dari bulan Maret –
                        Desember 2021.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci2">
                    <AccordionItemHeading id="ach2">
                      <AccordionItemButton>
                        2. Kapan periode Wish For Home berlangsung?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Periode 1 : 6 Mar- 30 Jun 2021
                        <br />
                        Periode 2 : Jul-Sept 2021
                        <br />
                        Periode 3 : Okt – Des 2021
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci3">
                    <AccordionItemHeading id="ach3">
                      <AccordionItemButton>
                        3. Berapa besar keringanan yang ditawarkan tiap
                        bulannya?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Untuk Properti / Non Kavling</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Disc 10 %* , 10% insentif huni Free BPHTB</td>
                            <td>Disc 10 %* , 5% insentif huni Free BPHTB</td>
                            <td>Disc 7,5 %* , 2,5% insentif huni Free BPHTB</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>Untuk Kavling</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Disc 15 %* , 10% insentif bangun Free BPHTB, Biaya
                              Pengalihan
                            </td>
                            <td>
                              Disc 15 %* , 5% insentif bangun Free BPHTB, Biaya
                              Pengalihan
                            </td>
                            <td>
                              Disc 12,5 %* , 2,5% insentif bangun Free BPHTB,
                              Biaya Pengalihan
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>Untuk Apartment Klaska - Surabaya</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Disc 10 %* , 10% insentif huni</td>
                            <td>Disc 10 %* , 5% insentif huni</td>
                            <td>Disc 7,5 %* , 2,5% insentif huni</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>Untuk Apartment The Elements - Jakarta</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Disc 15 %* , 5% insentif huni</td>
                            <td>Disc 15 %* , 2,5% insentif huni</td>
                            <td>Disc 12,5 %* , 2,5% insentif huni</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>Untuk Apartment Southgate Elegance - Jakarta</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Subsidi DP 15%, 5% insentif huni, FREE Biaya KPA
                              3%, Admin fee 1 juta
                            </td>
                            <td>
                              Subsidi DP 15%, FREE Biaya KPA 3%, Admin fee 1
                              juta
                            </td>
                            <td>Subsidi DP 12,5%</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>Untuk Apartment Southgate Altuera - Jakarta</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Subsidi DP 15%, FREE Biaya KPA 3%, Admin fee 1
                              juta
                            </td>
                            <td>Subsidi DP 15%</td>
                            <td>Subsidi DP 12,5%</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>Untuk Apartment Aerium - Jakarta</p>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Periode 1 (Mar-Jun)</th>
                            <th>Periode 2 (Jul-Sep)</th>
                            <th>Periode 3 (Okt-Des)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Disc 12,5 % , Subsidi DP 15%</td>
                            <td>Disc 10 % , Subsidi DP 15%</td>
                            <td>Disc 7,5 % , Subsidi DP 15%</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>*syarat dan ketentuan berlaku</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci4">
                    <AccordionItemHeading id="ach4">
                      <AccordionItemButton>
                        4. Proyek apa saja yang ditawarkan dalam program Wish
                        For Home? Dan dimana saja lokasinya?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        BSD City residential dan commercial, Kota Wisata -
                        Cibubur, Legenda Wisata - Cibubur, Nuvasa Bay - Batam,
                        Klaska - Surabaya, Wisata Bukit Mas - Surabaya, Grand
                        City Balikpapan - Balikpapan, The Elements - Jakarta,
                        Aerium - Jakarta, Southgate – Jakarta.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci5">
                    <AccordionItemHeading id="ach5">
                      <AccordionItemButton>
                        5. Jenis produk apa saja yang ditawarkan dalam program
                        Wish For Home?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Non Kavling, Kavling dan Apartment Jakarta.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci6">
                    <AccordionItemHeading id="ach6">
                      <AccordionItemButton>
                        6. Bagaimana cara mendapatkan keringanan selama program
                        Wish For Home?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <img src={ans6} style={{ width: "100%" }} />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci7">
                    <AccordionItemHeading id="ach7">
                      <AccordionItemButton>
                        7. Bagaimana cara saya mendapatkan price list?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Bisa dengan mengunjungi www.sinarmasland.com/wishforhome
                        , menghubungi property agent/sales marketing Sinar Mas
                        Land atau kunjungi social media IG @sinarmas_land , FB
                        Sinar Mas Land.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci8">
                    <AccordionItemHeading id="ach8">
                      <AccordionItemButton>
                        8. Bagaimana cara saya mendapatkan keringanan biaya berupa insentif PPN dari pemerintah?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Insentif PPN didapatkan untuk pembelian produk Non Kavling, Apartemen dan Ruko yang siap huni dan dapat diserahkan secara fisik pada periode pemberian insentif, yaitu Maret 2021 s/d Desember 2021. Adapun besaran insentif PPN sebagai berikut:<br/>
                        - Subsidi 100% untuk produk Non Kavling, Apartemen dan Ruko dengan harga sampai dengan 2 Miliar Rupiah<br/>
                        - Subsidi 50% untuk produk Non Kavling, Apartemen dan Ruko dengan rentang harga 2-5 Miliar Rupiah
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Section>
    </Layout>
  )
}
export default WishForHomeFaqPage
